import {axios} from '@/utils/request'

const apiPath = {
    login: '/passport/login',
    logout: '/passport/logout',
    sendCode: '/passport/sendCode',
    register: '/passport/register',
    registerAdmin: '/passport/registerAdmin',
    reset: '/passport/reset',
}

/**
 * 用户登录
 * @param {*} data
 */
export function login(data) {
    return axios({
        url: apiPath.login,
        method: 'post',
        data
    })
}

export function register(data) {
    return axios({
        url: apiPath.register,
        method: 'post',
        data
    })
}

export function registerAdmin(data) {
    return axios({
        url: apiPath.registerAdmin,
        method: 'post',
        data
    })
}

export function reset(data) {
    return axios({
        url: apiPath.reset,
        method: 'post',
        data
    })
}

export function sendCode(data) {
    return axios({
        url: apiPath.sendCode,
        method: 'post',
        data
    })
}

export function logout() {
    return axios({
        url: apiPath.logout,
        method: 'post'
    })
}
