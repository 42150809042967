import {axios} from '@/utils/request'

const pub = "inspect.inspectCategory";
/**
 * api接口列表
 */
const api = {
    info: '/' + pub + '/info',
    list: '/' + pub + '/list',
    add: '/' + pub + '/add',
    edit: '/' + pub + '/edit',
    delete: '/' + pub + '/delete',
    allList: '/' + pub + '/allList',
    tree_list: '/' + pub + '/treeList',
}

/**
 * 获取详细
 */
export function getInfo() {
    return axios({
        url: api.info,
        method: 'get'
    })
}

/**
 * 获取树结构项目
 */
export function getTreeList() {
    return axios({
        url: api.tree_list,
        method: 'get'
    })
}

/**
 * 列表
 */
export function list(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

/**
 * 列表
 */
export function allList(params) {
    return axios({
        url: api.allList,
        method: 'get',
        params
    })
}

/**
 * 新增记录
 * @param {*} data
 */
export function add(data) {
    return axios({
        url: api.add,
        method: 'post',
        data
    })
}

/**
 * 编辑记录
 * @param {*} data
 */
export function edit(data) {
    return axios({
        url: api.edit,
        method: 'post',
        data
    })
}

/**
 * 删除记录
 * @param {*} data
 */
export function deleted(data) {
    return axios({
        url: api.delete,
        method: 'post',
        data: data
    })
}
