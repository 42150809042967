<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      @ok="submit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div style="text-align: center;margin-top: 20px">
        <a-textarea
            v-model="text"
            placeholder="输入驳回理由"
            :auto-size="{ minRows: 6, maxRows: 6 }"
        />
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/inspect/inspectVehicle'

import {UploadFile, UploadImage} from '@/components'
import {isVideo} from '@/utils/util'

export default {
  components: {
    UploadFile,
    UploadImage
  },
  data() {
    return {
      // 对话框标题
      title: '驳回理由',
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      text: ''
    }
  },
  methods: {
    /**
     * 显示对话框
     */
    open() {
      this.visible = true
    },

    submit() {
      this.$emit('handleSuccess', this.text)
      this.handleCancel()
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.text = ''

    },
  }
}
</script>
<style scoped lang="less">

.image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px #e8e8e8 solid;
  background-size: cover;
}

.table {
  border: 1px #e8e8e8 solid;
  text-indent: 10px;


  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      //padding: 0 10px;
      min-height: 40px;
      line-height: 40px;

      width: 100%;
      //height: 100%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .std {
        border-bottom: 1px #d9d9d9 solid;
        min-height: 40px;

        &:last-child {
          border-bottom: none;
        }

        .sstd {
          min-height: 40px;

          .image {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: 1px #e8e8e8 solid;
            background-size: cover;
            margin: 0 2px;
          }

          display: flex;
          justify-content: center;

          div {
            width: 100%;
          }
        }
      }
    }

    .f600 {
      font-weight: 600;
    }

    .video {
      display: flex;
      justify-content: center;

      .image {
        margin-right: 5px;
      }
    }
  }
}

</style>
