<template>
  <div>
    <div class="list">
      <div class="list-item" v-for="(item,index) in file_list">
        <a target="_blank" :href="item">
          <img class="cover" :src="item"/>
        </a>
        <div class="del-btn" @click="handleDel(index)" v-if="!showImg">
          <img src="@/assets/del.png">
        </div>
      </div>
      <a-upload
          v-if="file_list.length<maxFileNumber && !showImg"
          name="iFile"
          :accept="accept"
          :beforeUpload="beforeUpload"
          :customRequest="onUpload"
          :showUploadList="false"
      >
        <div class="list-item">
          <img class="upcover" src="@/assets/upload.png"/>
        </div>
      </a-upload>
    </div>
  </div>
</template>
<script>
import {debounce, isVideo} from '@/utils/util'
import * as UploadApi from '@/api/upload'
import PropTypes from "ant-design-vue/lib/_util/vue-types";
import cloneDeep from 'lodash.clonedeep'

export default {
  name: 'MultipleImgUrlUpload',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    file: PropTypes.array.def([]),
    accept: PropTypes.string.def('image/*'),
    maxFileNumber: PropTypes.integer.def(5),
    showImg: PropTypes.bool.def(false),
  },
  watch: {
    file: {
      immediate: true,
      handler(val) {
        const {file_list, allowProps} = this
        if (val.length && !file_list.length && allowProps) {
          this.file_list = cloneDeep(val)
          this.onChange()
        }
      }
    }
  },
  data() {
    return {
      uploadUrl: UploadApi.image,
      // 文件列表
      file_list: [],
      // 禁止传参 (防止selectedItems为空时defaultList重新赋值)
      allowProps: true
    }
  },
  methods: {
    isVideo,
    // 事件: 上传文件之前
    beforeUpload(file) {
      const that = this
      return new Promise((resolve, reject) => {
        const showErrorMsg = debounce(this.$message.error, 20)
        return resolve(true)
      })
    },
    // 事件: 自定义上传
    onUpload(info) {
      const formData = new FormData()
      formData.append('iFile', info.file)
      formData.append('groupId', 0)
      // 开始上传
      this.uploadUrl(formData)
          .then(({data: {fileInfo: {preview_url}}}) => {
            this.file_list.push(preview_url)
            this.onChange()
          })
    },
    onChange() {
      if (this.file_list.length <= 0) {
        return this.$emit('change', '')
      }
      return this.$emit('change', this.file_list)
    },

    // 删除图片
    handleDel(index) {
      this.file_list.splice(index, 1)
      if (this.file_list.length === 0) {
        this.allowProps = false
      }
      this.onChange()
    }
  }
}
</script>

<style scoped lang='less'>
.list {
  display: flex;

  .list-item {
    width: 70px;
    height: 70px;
    border: 1px #d9d9d9 solid;
    border-radius: 5px;
    color: #d9d9d9;
    text-align: center;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 26px;
    position: relative;
    .cover {
      width: 68px;
      height: 68px;
    }

    .upcover {
      width: 38px;
      height: 38px;
    }

    .del-btn {
      position: absolute;
      top: 0;
      right: 0;
      background: red;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    &:last-child {
      margin-right: 0
    }
  }
}

.image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px dashed #e8e8e8;
  color: #dad9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px dashed #40a9ff;
    color: #40a9ff;
  }

  .icon-plus {
    font-size: 32px;
  }
}
</style>
