<template>
  <a-tree-select
      v-model="sValue"
      style="width: 100%"
      :tree-data="treeData"
      :placeholder="placeholder"
      tree-checkable
      :show-checked-strategy="SHOW_PARENT"
      @change="onChange"
  />
</template>
<script>
import * as Api from '@/api/inspect/inspectCategory'
import PropTypes from "ant-design-vue/lib/_util/vue-types";
import {TreeSelect} from 'ant-design-vue';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  name: 'SelectInspectProjectTree',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // v-model 指定选中项
    value: PropTypes.array.def(),
    // 元素的尺寸(宽)
    placeholder: PropTypes.string.def('请选择项目')
  },
  data() {
    return {
      treeData: [],
      SHOW_PARENT,
      // 选中项
      sValue: [],
      // 级联选择器数据
      options: []
    }
  },
  watch: {
    value(val) {
      this.sValue = val
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      Api.getTreeList().then(({data: {list}}) => {
        this.treeData = list
      })
    },
    onChange(value) {
      console.log(value)
      this.$emit('change', value)
    }
  }
}

</script>

<style scoped lang="less">

</style>
