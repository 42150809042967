// eslint-disable-next-line
import {BasicLayout, UserLayout} from '@/layouts'
import * as Icons from '@/core/icons'

const RouteView = {
    name: 'RouteView',
    render: (h) => h('router-view')
}

/**
 * 路由配置说明：
 * 建议：sider menu 请不要超过三级菜单，若超过三级菜单，则应该设计为顶部主菜单 配合左侧次级菜单
 // {
 //   redirect: noredirect,  //重定向
 //   name: 'router-name',   //路由名称
 //   hidden: true,          //可以在菜单中不展示这个路由，包括子路由。效果可以查看 other 下的路由配置。
 //   meta: {
 //     title: 'title',      //菜单项名称
 //     icon: 'a-icon',      //菜单项图标
 //     keepAlive: true,     //缓存页面
 //     permission：[string]   //用来配置这个路由的权限，如果配置了将会验证当前用户的权限，并决定是否展示 *（默认情况下）
 //   }
 // }
 **/
export const asyncRouterMap = [
    {
        path: '/',
        name: 'root',
        component: BasicLayout,
        children: [
            {
                path: '/staging',
                name: 'staging',
                component: () => import(/* webpackChunkName: "index" */ '@/views/staging/Index'),
                meta: {title: '工作台', icon: Icons.home, keepAlive: true, permission: ['/staging']}
            },
            // 企业信息
            {
                path: '/store',
                name: 'store',
                component: RouteView,
                meta: {title: '企业信息', icon: Icons.enterprise, permission: ['/store']},
                children: [
                    {
                        path: '/store/info',
                        component: () => import( '@/views/store/Setting'),
                        meta: {title: '企业信息', keepAlive: false, permission: ['/store/info']}
                    },
                    {
                        path: '/store/system',
                        component: () => import( '@/views/store/system/Index'),
                        meta: {title: '企业制度', keepAlive: false, permission: ['/store/system']}
                    },
                    {
                        path: '/store/contract',
                        // component: RouteView,
                        meta: {title: '企业合同', keepAlive: false, permission: ['/store/contract']},
                        component: () => import( '@/views/store/contract/Index'),
                        // {
                        //     path: '/contract/template',
                        //     component: () => import( '@/views/store/contract/template/Index.vue'),
                        //     meta: {title: '合同模板', keepAlive: false, permission: ['/contract/template']}
                        // },
                        // ]
                    },
                ]
            },
            // 车辆管理
            {
                path: '/vehicle',
                name: 'vehicle',
                component: RouteView,
                redirect: '/vehicle/list',
                meta: {
                    title: '车辆管理',
                    icon: Icons.vehicle, permission: ['/vehicle']
                },
                children: [
                    {
                        path: '/vehicle/list',
                        component: () => import( '@/views/vehicle/index/Index'),
                        meta: {title: '车辆列表', keepAlive: false, permission: ['/vehicle/list']},
                        activePath: ['/vehicle/3d']
                    },
                    {
                        path: '/vehicle/3d',
                        component: () => import( '@/views/vehicle/3d/Index'),
                        meta: {hidden: false, title: '3D展示', keepAlive: false, permission: ['/vehicle/3d']},
                        hidden: true
                    },
                ]
            },
            // 驾押管理
            {
                path: '/personnel',
                name: 'personnel',
                component: RouteView,
                meta: {
                    title: '人员管理',
                    icon: Icons.driver,
                    keepAlive: false,
                    permission: ['/personnel']
                },
                redirect: '/personnel/staff',
                children: [
                    {
                        path: '/personnel/staff',
                        name: 'personnelStaff',
                        component: RouteView,
                        // component: () => import( '@/views/personnel/staff/Index'),
                        meta: {title: '员工列表', keepAlive: false, permission: ['/personnel/staff']},
                        children: [
                            {
                                path: '/personnel/staff/driver',
                                component: () => import( '@/views/personnel/staff/driver'),
                                meta: {title: '驾驶员列表', keepAlive: false, permission: ['/personnel/staff/driver']}
                            },
                            {
                                path: '/personnel/staff/supercargo',
                                component: () => import( '@/views/personnel/staff/supercargo'),
                                meta: {
                                    title: '押运员列表',
                                    keepAlive: false,
                                    permission: ['/personnel/staff/supercargo']
                                }
                            },
                        ]
                    },
                    {
                        path: '/manage',
                        name: 'manage',
                        component: RouteView,
                        redirect: '/manage/user/index',
                        meta: {title: '系统管理员', icon: Icons.manage, permission: ['/manage']},
                        children: [
                            {
                                path: '/manage/user/index',
                                component: () => import( '@/views/manage/user/Index'),
                                meta: {title: '管理员列表', keepAlive: false, permission: ['/manage/user/index']}
                            },
                            {
                                path: '/manage/role/index',
                                component: () => import( '@/views/manage/role/Index'),
                                meta: {title: '角色管理', keepAlive: false, permission: ['/manage/role/index']}
                            }
                        ]
                    },
                ]
            },
            // 安全管理
            {
                path: '/secure',
                name: 'secure',
                component: RouteView,
                meta: {title: '安全管理', icon: Icons.secure, permission: ['/secure']},
                children: [
                    {
                        path: '/secure/vehicleInspect',
                        component: RouteView,
                        redirect: '/secure/inspect',
                        meta: {title: '车辆检查', keepAlive: false, permission: ['/secure/vehicleInspect']},
                        children: [
                            {
                                path: '/secure/inspect',
                                component: () => import( '@/views/secure/inspect/Index'),
                                meta: {
                                    title: '检查审核',
                                    keepAlive: false,
                                    permission: ['/secure/inspect']
                                },
                            },
                            {
                                path: '/secure/spotcheck',
                                component: () => import( '@/views/secure/inspect/Spotcheck'),
                                meta: {
                                    title: '抽查审核',
                                    keepAlive: false,
                                    permission: ['/secure/spotcheck']
                                },
                            },
                            {
                                path: '/secure/inspectSetting',
                                component: () => import( '@/views/secure/inspectSetting.vue'),
                                meta: {title: '检查内容设置', keepAlive: false, permission: ['/secure/inspectSetting']}
                            },
                        ]
                    },
                    {
                        path: '/secure/train',
                        component: () => import( '@/views/secure/train/Index'),
                        meta: {title: '安全培训', keepAlive: false, permission: ['/secure/train']},
                    },
                ]
            },
            // 订单管理
            {
                path: '/order',
                name: 'order',
                component: RouteView,
                redirect: '/order/inProgress',
                meta: {title: '订单管理', icon: Icons.order, permission: ['/order']},
                children: [
                    {
                        path: '/order/inProgress',
                        name: 'orderInProgress',
                        component: () => import( '@/views/order/inProgress/Index'),
                        meta: {title: '进行中订单', keepAlive: false, permission: ['/order/inProgress']}
                    },
                    {
                        path: '/order/completed',
                        name: 'orderCompleted',
                        component: () => import('@/views/order/completed/Index'),
                        meta: {title: '已完成订单', keepAlive: false, permission: ['/order/completed']}
                    },
                ]
            },
            // 数据中心
            {
                path: '/data',
                name: 'data',
                component: RouteView,
                meta: {
                    title: '数据中心', icon: Icons.data,
                    permission: ['/data']
                },
                children: [
                    {
                        path: '/data/address',
                        component: () => import( '@/views/data/address/Index'),
                        meta: {title: '地址管理', keepAlive: false, permission: ['/data/address']}
                    },
                    {
                        path: '/data/goodsName',
                        component: () => import( '@/views/data/goodsName/Index'),
                        meta: {title: '货物品名', keepAlive: false, permission: ['/data/goodsName']}
                    },
                    {
                        path: '/data/custom',
                        component: () => import( '@/views/data/custom/Index'),
                        meta: {title: '客户列表', keepAlive: false, permission: ['/data/custom']}
                    },
                    {
                        path: '/data/waybill',
                        component: () => import( '@/views/data/waybill/Index'),
                        meta: {title: '运输路线', keepAlive: false, permission: ['/data/waybill']}
                    },
                    {
                        path: '/data/shipper',
                        component: () => import( '@/views/data/shipper/Index'),
                        meta: {title: '托运人', keepAlive: false, permission: ['/data/shipper']}
                    },
                    {
                        path: '/data/cargoOwner',
                        component: () => import( '@/views/data/cargoOwner/Index'),
                        meta: {title: '货主', keepAlive: false, permission: ['/data/cargoOwner']}
                    },
                    {
                        path: '/data/calculator',
                        component: () => import( '@/views/data/calculator/Index'),
                        meta: {title: '运费计算器', keepAlive: false, permission: ['/data/calculator']}
                    },

                ]
            },
            {
                path: '/statistics',
                name: 'statistics',
                component: RouteView,
                meta: {
                    title: '统计中心', icon: Icons.statistics,
                    permission: ['/statistics']
                },
                children: [
                    {
                        path: '/statistics/contrast',
                        component: () => import( '@/views/statistics/contrast/Index'),
                        meta: {title: '运营分析', keepAlive: false, permission: ['/statistics/contrast']}
                    },
                    {
                        path: '/data/cost',
                        component: () => import( '@/views/data/cost/Index'),
                        meta: {title: '费用项目', keepAlive: false, permission: ['/data/cost']}
                    },
                    {
                        path: '/statistics/staff',
                        component: () => import( '@/views/statistics/staff/Index'),
                        meta: {title: '驾押工资统计', keepAlive: false, permission: ['/statistics/staff']}
                    },
                    {
                        path: '/statistics/expenditure',
                        component: () => import( '@/views/statistics/expenditure/Index'),
                        meta: {title: '驾押开支统计', keepAlive: false, permission: ['/statistics/expenditure']}
                    },
                    {
                        path: '/statistics/vehicle',
                        component: () => import( '@/views/statistics/vehicle/Index'),
                        meta: {title: '车辆运营统计', keepAlive: false, permission: ['/statistics/vehicle']}
                    },
                    {
                        path: '/statistics/custom',
                        component: () => import( '@/views/statistics/custom/Index'),
                        meta: {title: '客户账单统计', keepAlive: false, permission: ['/statistics/custom']}
                    },
                    {
                        path: '/setting/personnelSalary',
                        component: () => import(/* webpackChunkName: "setting" */ '@/views/setting/personnelSalary/Setting'),
                        meta: {title: '驾押工资设置', keepAlive: false, permission: ['/setting/personnelSalary']}
                    },
                    {
                        path: '/setting/vehicleBill',
                        component: () => import(/* webpackChunkName: "setting" */ '@/views/setting/vehicleBill/Setting'),
                        meta: {title: '车辆运营设置', keepAlive: false, permission: ['/setting/vehicleBill']}
                    },
                ]
            },
            // 仓库管理
            {
                path: '/warehouse',
                name: 'warehouse',
                component: RouteView,
                meta: {title: '仓库管理', icon: Icons.warehouse, keepAlive: false, permission: ['/warehouse']},
                children: [
                    {
                        path: '/warehouse/toolList',
                        component: () => import( '@/views/warehouse/tool/Index'),
                        meta: {title: '物品库存', keepAlive: false, permission: ['/warehouse/toolList']}
                    },
                    {
                        path: '/warehouse/approve',
                        component: () => import( '@/views/warehouse/approve/Index'),
                        meta: {title: '领取记录', keepAlive: false, permission: ['/warehouse/approve']}
                    },
                    {
                        path: '/warehouse/procure',
                        component: () => import( '@/views/warehouse/procure/Index'),
                        meta: {title: '采购记录', keepAlive: false, permission: ['/warehouse/procure']}
                    },
                ]
            },
            // 消息中心
            {
                path: '/message',
                name: 'message',
                component: () => import( '@/views/message/Index'),
                meta: {title: '消息中心', icon: Icons.message, permission: ['/message']},
            },

            //统计管理
            // {
            //     path: '/statistics',
            //     name: 'statistics',
            //     component: () => import( '@/views/wages/Index'),
            //     meta: {title: '统计管理', keepAlive: false, permission: ['/wages']},
            // },
            // 商品管理
            // {
            //   path: '/goods',
            //   name: 'goods',
            //   component: RouteView,
            //   redirect: '/goods/index',
            //   meta: { title: '商品管理', icon: Icons.goods, permission: ['/goods'] },
            //   children: [
            //     {
            //       path: '/goods/index',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/Index'),
            //       meta: { title: '商品列表', keepAlive: false, permission: ['/goods/index'] },
            //       // 访问其他页面时激活该菜单(router-link-active)
            //       activePath: ['/goods/create', '/goods/update']
            //     },
            //     {
            //       path: '/goods/create',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/Create'),
            //       meta: { title: '创建商品', keepAlive: false, permission: ['/goods/create'] },
            //       hidden: true
            //     },
            //     {
            //       path: '/goods/update',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/Update'),
            //       meta: { title: '编辑商品', keepAlive: false, permission: ['/goods/update'] },
            //       hidden: true
            //     },
            //     {
            //       path: '/goods/category/index',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/category/Index'),
            //       meta: { title: '商品分类', keepAlive: false, permission: ['/goods/category/index'] }
            //     },
            //     // {
            //     //   path: '/goods/spec-template/index',
            //     //   component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/service/Index'),
            //     //   meta: { title: '规格模板', keepAlive: false, permission: ['/goods/spec-template/index'] }
            //     // },
            //     {
            //       path: '/goods/service/index',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/service/Index'),
            //       meta: { title: '服务承诺', keepAlive: false, permission: ['/goods/service/index'] }
            //     },
            //     {
            //       path: '/goods/comment/index',
            //       component: () => import(/* webpackChunkName: "goods" */ '@/views/goods/comment/Index'),
            //       meta: { title: '商品评价', keepAlive: false, permission: ['/goods/comment/index'] }
            //     }
            //   ]
            // },
            //
            // 订单管理
            // {
            //   path: '/order',
            //   name: 'order',
            //   component: RouteView,
            //   redirect: '/order/list/all',
            //   meta: { title: '订单管理', icon: Icons.order, permission: ['/order'] },
            //   children: [
            //     {
            //       path: '/order/list/all',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '全部订单', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/list/delivery',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '待发货', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/list/receipt',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '待收货', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/list/pay',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '待付款', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/list/complete',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '已完成', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/list/cancel',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Index'),
            //       meta: { title: '已取消', keepAlive: false, permission: ['/order/list/all'] }
            //     },
            //     {
            //       path: '/order/detail',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/Detail'),
            //       meta: { title: '订单详情', keepAlive: false, permission: ['/order/detail'] },
            //       hidden: true
            //     },
            //     {
            //       path: '/order/refund/index',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/refund/Index'),
            //       meta: { title: '售后管理', keepAlive: false, permission: ['/order/refund/index'] },
            //       activePath: ['/order/refund/detail']
            //     },
            //     {
            //       path: '/order/refund/detail',
            //       component: () => import(/* webpackChunkName: "order" */ '@/views/order/refund/Detail'),
            //       meta: { title: '售后单详情', keepAlive: false, permission: ['/order/refund/detail'] },
            //       hidden: true
            //     },
            //     {
            //       path: '/order/tools',
            //       component: RouteView,
            //       meta: { title: '订单处理', keepAlive: false, permission: ['/order/tools'] },
            //       children: [
            //         {
            //           path: '/order/tools/export',
            //           component: () => import(/* webpackChunkName: "order" */ '@/views/order/tools/Export'),
            //           meta: { title: '订单导出', keepAlive: false, permission: ['/order/tools/export'] }
            //         },
            //       ]
            //     },
            //   ]
            // },

            // 会员管理
            // {
            //   path: '/user',
            //   name: 'user',
            //   component: RouteView,
            //   meta: { title: '会员管理', icon: Icons.user, permission: ['/user'] },
            //   children: [
            //     {
            //       path: '/user/index',
            //       component: () => import(/* webpackChunkName: "user" */ '@/views/user/Index'),
            //       meta: { title: '会员列表', keepAlive: false, permission: ['/user/index'] }
            //     },
            //     {
            //       path: '/user/grade/index',
            //       component: () => import(/* webpackChunkName: "user" */ '@/views/user/grade/Index'),
            //       meta: { title: '会员等级', keepAlive: false, permission: ['/user/grade/index'] }
            //     },
            //     {
            //       path: '/user/balance',
            //       component: RouteView,
            //       redirect: '/user/balance/index',
            //       meta: { title: '余额记录', keepAlive: false, permission: ['/user/balance'] },
            //       children: [
            //         {
            //           path: '/user/recharge/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/user/recharge/Index'),
            //           meta: { title: '充值记录', keepAlive: false, permission: ['/user/recharge/index'] }
            //         },
            //         {
            //           path: '/user/balance/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/user/balance/Index'),
            //           meta: { title: '余额明细', keepAlive: false, permission: ['/user/balance/index'] }
            //         }
            //       ]
            //     }
            //   ]
            // },

            // 内容管理
            // {
            //   path: '/content',
            //   name: 'content',
            //   component: RouteView,
            //   meta: { title: '内容管理', icon: Icons.content, permission: ['/content'] },
            //   children: [
            //     {
            //       path: '/content/article',
            //       component: RouteView,
            //       redirect: '/content/article/index',
            //       meta: { title: '文章管理', keepAlive: false, permission: ['/content/article'] },
            //       children: [
            //         {
            //           path: '/content/article/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/content/article/Index'),
            //           meta: { title: '文章列表', keepAlive: false, permission: ['/content/article/index'] }
            //         },
            //         {
            //           path: '/content/article/category/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/content/article/category/Index'),
            //           meta: { title: '文章分类', keepAlive: false, permission: ['/content/article/category/index'] }
            //         }
            //       ]
            //     },
            //     {
            //       path: '/content/files',
            //       component: RouteView,
            //       redirect: '/content/files/index',
            //       meta: { title: '文件库管理', keepAlive: false, permission: ['/content/files'] },
            //       children: [
            //         {
            //           path: '/content/files/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/content/files/Index'),
            //           meta: { title: '文件列表', keepAlive: false, permission: ['/content/files/index'] }
            //         },
            //         {
            //           path: '/content/files/group/index',
            //           component: () => import(/* webpackChunkName: "content" */ '@/views/content/files/group/Index'),
            //           meta: { title: '文件分组', keepAlive: false, permission: ['/content/files/group/index'] }
            //         }
            //       ]
            //     },
            //     {
            //       path: '/content/help/index',
            //       component: () => import(/* webpackChunkName: "content" */ '@/views/content/help/Index'),
            //       meta: { title: '帮助中心', keepAlive: false, permission: ['/content/help/index'] }
            //     }
            //   ]
            // },

            // 营销管理
            // {
            //   path: '/market',
            //   name: 'market',
            //   component: RouteView,
            //   meta: { title: '营销管理', icon: Icons.market, permission: ['/market'] },
            //   children: [
            //     {
            //       path: '/market/coupon',
            //       component: RouteView,
            //       redirect: '/market/coupon/index',
            //       meta: { title: '优惠券', keepAlive: false, permission: ['/market/coupon'] },
            //       children: [
            //         {
            //           path: '/market/coupon/index',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/coupon/Index'),
            //           meta: { title: '优惠券列表', keepAlive: false, permission: ['/market/coupon/index'] },
            //           // 访问其他页面时激活该菜单(router-link-active)
            //           activePath: ['/market/coupon/create', '/market/coupon/update']
            //         },
            //         {
            //           path: '/market/coupon/create',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/coupon/Create'),
            //           meta: { title: '创建优惠券', keepAlive: false, permission: ['/market/coupon/create'] },
            //           hidden: true
            //         },
            //         {
            //           path: '/market/coupon/update',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/coupon/Update'),
            //           meta: { title: '编辑优惠券', keepAlive: false, permission: ['/market/coupon/update'] },
            //           hidden: true
            //         },
            //         {
            //           path: '/market/coupon/receive/index',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/coupon/Receive'),
            //           meta: { title: '领券记录', keepAlive: false, permission: ['/market/coupon/receive/index'] }
            //         }
            //       ]
            //     },
            //     {
            //       path: '/market/recharge',
            //       component: RouteView,
            //       redirect: '/market/recharge/plan/index',
            //       meta: { title: '会员充值', keepAlive: false, permission: ['/market/recharge'] },
            //       children: [
            //         {
            //           path: '/market/recharge/plan/index',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/recharge/plan/Index'),
            //           meta: { title: '充值套餐', keepAlive: false, permission: ['/market/recharge/plan/index'] }
            //         },
            //         {
            //           path: '/market/recharge/setting',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/recharge/Setting'),
            //           meta: { title: '充值设置', keepAlive: false, permission: ['/market/recharge/setting'] }
            //         }
            //       ]
            //     },
            //     {
            //       path: '/market/points',
            //       component: RouteView,
            //       redirect: '/market/points/setting',
            //       meta: { title: '积分管理', keepAlive: false, permission: ['/market/points'] },
            //       children: [
            //         {
            //           path: '/market/points/setting',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/points/Setting'),
            //           meta: { title: '积分设置', keepAlive: false, permission: ['/market/points/setting'] }
            //         },
            //         {
            //           path: '/market/points/log',
            //           component: () => import(/* webpackChunkName: "market" */ '@/views/market/points/Log'),
            //           meta: { title: '积分明细', keepAlive: false, permission: ['/market/points/log'] }
            //         }
            //       ]
            //     },
            //     {
            //       path: '/market/full-free',
            //       component: () => import(/* webpackChunkName: "market" */ '@/views/market/FullFree'),
            //       meta: { title: '满额包邮', keepAlive: false, permission: ['/market/full-free'] }
            //     }
            //   ]
            // },

            // 数据统计
            // {
            //   path: '/statistics',
            //   name: 'statistics',
            //   component: () => import(/* webpackChunkName: "statistics" */ '@/views/statistics/Index'),
            //   meta: { title: '数据统计', keepAlive: true, icon: Icons.statistics, permission: ['/statistics'] }
            // },

            // 客户端
            // {
            //     path: '/client',
            //     name: 'client',
            //     component: RouteView,
            //     meta: {
            //         title: '客户端',
            //         keepAlive: true,
            //         icon: Icons.mpWeixin,
            //         iconStyle: {fontSize: '17.2px', color: '#36b313'},
            //         permission: ['/client']
            //     },
            //     children: [
            //         // {
            //         //     path: '/client/register',
            //         //     component: () => import(/* webpackChunkName: "client" */ '@/views/client/Register'),
            //         //     meta: {title: '注册设置', keepAlive: false, permission: ['/client/register']}
            //         // },
            //         {
            //             path: '/client/wxapp',
            //             component: RouteView,
            //             redirect: '/client/wxapp/setting',
            //             meta: {title: '微信小程序', keepAlive: false, permission: ['/client/wxapp']},
            //             children: [
            //                 {
            //                     path: '/client/wxapp/setting',
            //                     component: () => import(/* webpackChunkName: "client" */ '@/views/client/wxapp/Setting'),
            //                     meta: {title: '小程序设置', keepAlive: false, permission: ['/client/wxapp/setting']}
            //                 }
            //             ]
            //         },
            //         // {
            //         //   path: '/client/h5',
            //         //   component: RouteView,
            //         //   redirect: '/client/h5/setting',
            //         //   meta: { title: 'H5端', keepAlive: false, permission: ['/client/h5'] },
            //         //   children: [
            //         //     {
            //         //       path: '/client/h5/setting',
            //         //       component: () => import(/* webpackChunkName: "client" */ '@/views/client/h5/Setting'),
            //         //       meta: { title: '站点设置', keepAlive: false, permission: ['/client/h5/setting'] }
            //         //     }
            //         //   ]
            //         // },
            //     ]
            // },

            {
                path: '/msds',
                name: 'msds',
                component: () => import( '@/views/msds/Index'),
                meta: {title: 'MSDS查询', icon: Icons.msds, permission: ['/msds']},
            },

            // 设置
            {
                path: '/setting',
                name: 'setting',
                component: RouteView,
                redirect: '/setting/store/basic',
                meta: {title: '设置', icon: Icons.setting, permission: ['/setting']},
                children: [

                    {
                        path: '/setting/storage',
                        component: () => import(/* webpackChunkName: "setting" */ '@/views/setting/Storage'),
                        meta: {title: '上传设置', keepAlive: false, permission: ['/setting/storage']}
                    },
                    {
                        path: '/setting/sms',
                        component: () => import(/* webpackChunkName: "setting" */ '@/views/setting/Sms'),
                        meta: {title: '短信通知', keepAlive: false, permission: ['/setting/sms']}
                    },
                    // {
                    //   path: '/setting/delivery',
                    //   component: RouteView,
                    //   redirect: '/setting/delivery/setting',
                    //   meta: { title: '配送设置', keepAlive: false, permission: ['/setting/delivery'] },
                    //   children: [
                    //     {
                    //       path: '/setting/delivery/setting',
                    //       component: () => import(/* webpackChunkName: "content" */ '@/views/setting/delivery/Setting'),
                    //       meta: { title: '配送方式', keepAlive: false, permission: ['/setting/delivery/setting'] }
                    //     },
                    //     {
                    //       path: '/setting/delivery/template/index',
                    //       component: () => import(/* webpackChunkName: "content" */ '@/views/setting/delivery/template/Index'),
                    //       meta: { title: '运费模板', keepAlive: false, permission: ['/setting/delivery/template/index'] },
                    //       // 访问其他页面时激活该菜单(router-link-active)
                    //       activePath: ['/setting/delivery/template/create', '/setting/delivery/template/update']
                    //     },
                    //     {
                    //       path: '/setting/delivery/template/create',
                    //       component: () => import(/* webpackChunkName: "content" */ '@/views/setting/delivery/template/Create'),
                    //       meta: { title: '新增运费模板', keepAlive: false, permission: ['/setting/delivery/template/create'] },
                    //       hidden: true
                    //     },
                    //     {
                    //       path: '/setting/delivery/template/update',
                    //       component: () => import(/* webpackChunkName: "content" */ '@/views/setting/delivery/template/Update'),
                    //       meta: { title: '编辑运费模板', keepAlive: false, permission: ['/setting/delivery/template/update'] },
                    //       hidden: true
                    //     },
                    //     {
                    //       path: '/setting/delivery/express/index',
                    //       component: () => import(/* webpackChunkName: "content" */ '@/views/setting/delivery/express/Index'),
                    //       meta: { title: '物流公司', keepAlive: false, permission: ['/setting/delivery/express/index'] }
                    //     }
                    //   ]
                    // },
                    {
                        path: '/setting/other',
                        component: RouteView,
                        redirect: '/setting/other/clear',
                        meta: {title: '其他设置', keepAlive: false, permission: ['/setting/other']},
                        children: [
                            {
                                path: '/setting/other/clear',
                                component: () => import(/* webpackChunkName: "content" */ '@/views/setting/other/Clear'),
                                meta: {title: '清理缓存', keepAlive: false, permission: ['/setting/other/clear']}
                            }
                        ]
                    }
                ]
            }

        ]
    },

    // 更新账户信息
    {
        name: 'renew',
        path: '/manage',
        redirect: '/manage/renew',
        component: BasicLayout,
        hidden: true,
        meta: {title: '更新账户信息', keepAlive: false},
        children: [
            {
                path: 'renew',
                component: () => import( '@/views/manage/renew')
            }
        ]
    },

    {
        path: '*', redirect: '/404', hidden: true
    }

]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [

    // 用户登录页
    {
        path: '/passport',
        component: UserLayout,
        redirect: '/passport/login',
        hidden: true,
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "passport" */ '@/views/passport/Login')
            }
        ]
    },
    // 404页
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "exception" */ '@/views/exception/404')
    },

    // 3d页
    {
        path: '/3d',
        component: () => import(/* webpackChunkName: "exception" */ '@/views/3d/Index')
    }
]
