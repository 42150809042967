/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */

// 左侧菜单栏
import home from '@/assets/icons/home.svg?inline'
import manage from '@/assets/icons/manage.svg?inline'
import goods from '@/assets/icons/goods.svg?inline'
import order from '@/assets/icons/order.svg?inline'
import content from '@/assets/icons/content.svg?inline'
import setting from '@/assets/icons/setting.svg?inline'
import shop from '@/assets/icons/shop.svg?inline'
import user from '@/assets/icons/user.svg?inline'
import market from '@/assets/icons/market.svg?inline'
import statistics from '@/assets/icons/statistics.svg?inline'
import apps from '@/assets/icons/apps.svg?inline'
import enterprise from '@/assets/icons/enterprise.svg?inline'
import vehicle from '@/assets/icons/vehicle.svg?inline'
import driver from '@/assets/icons/driver.svg?inline'
import warehouse from '@/assets/icons/warehouse.svg?inline'
import data from '@/assets/icons/data.svg?inline'
import message from '@/assets/icons/message.svg?inline'
import secure from '@/assets/icons/secure.svg?inline'
import msds from '@/assets/icons/msds.svg?inline'

// 通用图标
import arrowRight from '@/assets/icons/arrow-right.svg?inline'
import mpWeixin from '@/assets/icons/mp-weixin.svg?inline'
import h5Weixin from '@/assets/icons/h5-weixin.svg?inline'
import h5 from '@/assets/icons/h5.svg?inline'
import app from '@/assets/icons/app.svg?inline'

// 支付方式
import payAlipay from '@/assets/icons/payment/alipay.svg?inline'
import payBalance from '@/assets/icons/payment/balance.svg?inline'
import payWechat from '@/assets/icons/payment/wechat.svg?inline'

export {
    home, manage, goods, order, content, setting, shop, user, market, statistics, apps, enterprise,vehicle,driver,warehouse,data,message,secure,msds,
    arrowRight, h5, mpWeixin, h5Weixin, app,
    payAlipay, payBalance, payWechat
}
