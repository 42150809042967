<template>
  <div>
    <a-upload
        name="iFile"
        :accept="accept"
        :beforeUpload="beforeUpload"
        :customRequest="onUpload"
        :showUploadList="false"
    >
      <template v-if="fileInfo && sValue">
        <a-button :loading="uploadLoading">{{ btnText }}</a-button>
      </template>
      <template v-else>
        <a-button :loading="uploadLoading">{{ btnText }}</a-button>
      </template>
    </a-upload>

    <div v-if="fileInfo && sValue">
      <a :href="fileInfo.external_url" target="_blank"><img :style="'width:'+imgWidth+'px;height:'+imgHeight+'px;margin-top:0px'" :src="fileInfo.external_url"></a>
    </div>
  </div>
</template>
<script>
import {debounce} from '@/utils/util'
import * as UploadApi from '@/api/upload'

export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Number | String,
    file: {
      type: Object
    },
    avatar: {
      type: Boolean,
      default: false
    },
    ispng: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    btnText: {
      type: String,
      default: '选取图片'
    },
    imgWidth: {
      type: Number,
      default: 50
    },
    imgHeight: {
      type: Number,
      default: 50
    },

  },
  watch: {
    value(val) {
      this.sValue = val
    },
    file(val) {
      if (val.preview_url) {
        this.fileInfo = val
      }
    }
  },
  components: {},
  data() {
    return {
      sValue: '',
      uploadSizeLimit: 20,
      uploadUrl: UploadApi.annex,
      fileInfo: this.file,
      showImg: false,
      uploadLoading: false
    }
  },
  methods: {
    // 事件: 上传文件之前
    beforeUpload(file) {
      const that = this
      return new Promise((resolve, reject) => {
        const showErrorMsg = debounce(this.$message.error, 20)
        return resolve(true)
      })
    },
    // 事件: 自定义上传
    onUpload(info) {
      this.uploadLoading = true
      const formData = new FormData()
      formData.append('iFile', info.file)
      formData.append('groupId', 0)
      // 开始上传
      this.uploadUrl(formData)
          .then((res) => {
            this.fileInfo = res.data.fileInfo
            this.sValue = this.fileInfo.file_id
            this.uploadLoading = false
            this.onChange()
          })
    },
    onChange() {
      this.$emit('change', this.sValue, [this.fileInfo])
    }
  }
}
</script>

<style scoped lang='less'>
.image {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border: 1px dashed #e8e8e8;
  color: #dad9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px dashed #40a9ff;
    color: #40a9ff;
  }

  .icon-plus {
    font-size: 32px;
  }
}
</style>
