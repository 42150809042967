<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :footer="null"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      @cancel="cancel"
  >
    关键词搜索：
    <a-input-search style="margin-top: 5px" placeholder="请输入地址关键词" enter-button @search="onSearch"/>
    <template v-if="list.length>0">
      <div class="list">
        <div v-for="item in list" :class="['item',currId==item.address_id?'action':'']" @click="setCurr(item)">
          <div>
            <a-icon style="font-size: 22px;margin-right: 10px" type="environment"/>
          </div>
          <div>
            <div style="font-size: 16px;font-weight: bold">{{ item.address_name }}</div>
            <div>{{ item.address_detail }}</div>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center">
        <a-pagination v-model="queryParam.page" @change="reaList" :defaultPageSize="10" :total="total" show-less-items/>
      </div>
    </template>
    <template v-else>
      <div style="padding: 60px">
        <a-empty/>
      </div>
    </template>

  </a-modal>
</template>
<script>
import * as Api from '@/api/data/address'

export default {
  name: 'AddressSearchModel',
  data() {
    return {
      // 对话框标题
      title: '地址库',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      list: [],
      queryParam: {
        page: 1,
        keyword: ''
      },
      total: 0,
      currId: 0
    }
  },
  methods: {
    open() {
      this.visible = true
      this.getList()
    },
    cancel() {
      this.visible = false
    },
    setCurr(item) {
      this.currId = item.address_id
      this.$emit('handleSuccess', item)
      this.cancel()
    },

    async getList() {
      const {data: {list}} = await Api.gobList(this.queryParam)
      this.list = list.data
      this.page = list.current_page
      this.total = list.total
    },
    onSearch(keyword) {
      this.queryParam.keyword = keyword
      this.getList()
    },
    reaList(e) {
      this.queryParam.page = e
      this.getList()
    },
  }
}
</script>
<style scoped lang="less">
.list {
  padding: 10px 0;

  .item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-bottom: none;
    transition: all .3s;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid #e8e8e8;
    }

    &:hover {
      background: #9dc7ee;
      //color: #ffffff;
      border-color: #9dc7ee;
    }


  }

  .action {
    background: #9dc7ee;
    color: #ffffff;
    border-color: #9dc7ee;
  }
}
</style>
